@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700);

.panel {
    background: rgba(black, .3);
    box-shadow: 0 0 30px 10px rgba(black, .3);
    border-radius: 25px;
}

.panel_content {
    padding-bottom: 3em;
    background: #f6f6f6;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    color: #5a5a5a;
    text-align: center;
}

.panel_content h4 {
      padding: 1.5em 0;
      padding-inline: 2em;
      border-bottom: 2px solid #ededed;
      color: #6441a5;
      font-size: 1.15em;
      font-weight: 700;
      text-transform: uppercase;
}

.panel_content h2 {
      padding: .5em 1.5em;
      font-size: 3em;
      font-weight: 300;
}

.panel_content p {
      margin: auto;
      width: 55%;
      font-size: 1.15em;
      font-weight: 300;
      line-height: 1.4;
}

.panel_tiles {
    display: flex;
    perspective: 1000px;
}

.tile {
    background: #6441a5;
    height: 100px;
}

.outside {
    width: 100px;
}

.outside.left_outside {
    border-bottom-left-radius: 30px;
    transform-origin: top left;
}

.outside.right_outside {
    border-bottom-right-radius: 30px;
    transform-origin: top right;
}

.tile.panel_btn {
    width: calc((100% - 200px));
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #7c50cc;
    color: white;
    text-decoration: none;
    cursor: pointer;
    font-size: 2.25em;
    font-weight: 700;
    z-index: 1;
}

.panel_btn:hover {
    background-image: linear-gradient(to right, #7c50cc, #8453de, #7741dc, #7c50cc);  
    box-shadow: 0 5px 15px #44277a;
}

.box {
    border: 1.5px solid #2f2c2c;
    padding: 5px;
    width: fit-content;
    text-align: center;
    display: inline-block;
}

body {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2f2c2c;
    font-family: "Lato", Arial, sans-serif;
    perspective: 1000px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.footer {
    padding-top: 5px;
    text-align: center;
    color: #f6f6f6;
    text-decoration: none;
}